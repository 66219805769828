import React, { Component } from 'react';
import {withStyles} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import {FusePageSimple, FuseAnimateGroup} from '../../../../@fuse';
import {Icon, Typography, Tooltip, Card, CardContent, CardHeader, Button, Badge, LinearProgress, Box, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import ReactTable from "react-table";
import {connect} from 'react-redux';
import * as actions from '../../../store/api/actions';
import moment from 'moment';
import copyCodeToClipboard from '../../../components/Functions/CopyToClipboard';

const styles = theme => ({
    layoutRoot: {},
    datePicker: {
        // minWidth: '155px',
        width: '100%',
    },
    floatRight: {
        float: 'right',
        marginLeft: '4px',
    },
    cardHeader: {
        backgroundColor: '#f6f8f8',
        borderBottom: '1px solid',
        borderColor: '#dee5e7 !important;'
    },
    successButton: {
        color: '#27c24c !important'
    },
});

class DexxStockExport extends Component {

    state = {
        fromdate: new Date().toISOString().slice(0, 10),
        todate: new Date().toISOString().slice(0, 10),
        startLoading: false,
        finishedLoading: false,
        percentage: 0,
        // fromdate: new Date(),
        // fromdate: moment(this.getMonday(fromdate)).format('YYYY-MM-DD'),
        // todate: moment(new Date()).format('YYYY-MM-DD'),
    }

    componentDidMount() {
        this.handleDateButtonClick('thisweek');
        this.setFilterCompany(8);
        // this.handleGenerateClick();
        // if(this.props.generatedDexxStockExport.length > 0) {
        //     this.handleDateButtonClick('thisweek');
        // }
    }
    
    handleFromDate = event => {
        this.setState({fromdate: event.target.value})
    }
    
    handleToDate = event => {
        this.setState({todate: event.target.value})
    }

    handleGenerateClick = () => {
        this.setState({finishedLoading: false, startLoading: true, percentage: 0});
        this.props.onGenerateExport(this.state.filterCompany, this.state.fromdate, this.state.todate);
    }

    finishedLoading = () => {
        this.setState({finishedLoading: true, startLoading: false, percentage: 100});
    }

    downloadExcel = () => {
        this.props.onDownloadExcel(this.props.generatedStockExport, this.state.filterCompany);
    }
    
    applyDates = () => {
        // const momentFormatted = moment(this.state.startDate).format('YYYY-MM-DD');
        // console.log(momentFormatted);
        const startDate = moment(this.state.startDate).format('YYYY-MM-DD');
        const endDate = moment(this.state.endDate).format('YYYY-MM-DD');
        this.setState({fromdate: startDate, todate: endDate}, () => this.handleGenerateClick())
    }

    handleDateButtonClick = buttonClicked => {
        let startDate = new Date();
        let endDate = new Date();
        switch(buttonClicked) {
            case 'yesterday':
                startDate.setDate(startDate.getDate() - 1);
                endDate.setDate(endDate.getDate() - 1);
                break;
            case 'thisweek': 
                startDate = this.getMonday(startDate);
                break;
            case 'thismonth': 
                startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                break;
            case 'thisyear': 
                startDate = new Date(startDate.getFullYear(), 0, 1)
                break;
            case 'lastweek': 
                startDate.setDate(startDate.getDate()-7);
                startDate = this.getMonday(startDate);
                endDate = moment(startDate).add('days', 4).toDate();
                break;
            case 'lastmonth': 
                startDate = new Date(startDate.getFullYear(), startDate.getMonth()-1, 1);
                // startDate.setHours(-1);
                // startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                // startDate.setHours(-24);
                endDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
                endDate.setHours(-4);
                break;
            case 'lastyear': 
                startDate = new Date(startDate.getFullYear() - 1, 0, 1);
                endDate = new Date(endDate.getFullYear() - 1, 11, 31);
                break;
            case 'defaultdate': 
                startDate.setDate(startDate.getDate() - 1);
                endDate.setDate(endDate.getDate() - 1);
                break;
            default: break;
        }
        this.setState({startDate, endDate}, this.applyDates);        
    }

    getMonday = date => {
        let day = date.getDay() || 7;
        if( day !== 1 ) 
            date.setHours(-24 * (day - 1)); 
        return date;
    }

    // copyCodeToClipboard = (str) => {
    //     const el = document.createElement('textarea');
    //     el.value = str;
    //     document.body.appendChild(el);
    //     el.select();
    //     document.execCommand('copy');
    //     document.body.removeChild(el);
    // }

    setFilterCompany = (value) => {
        this.setState({filterCompany: value}, () => this.handleGenerateClick());
        // this.setState({fromdate: startDate, todate: endDate})
    }

    render() {
        const {classes, t, generatedStockExport} = this.props;
        console.log(" company : " + this.state.filterCompany);
        // console.log(" loading : " + this.state.loadingGeneratedStockExport);
        
        const companylist = [
            // {key: 'all', value: 'Tous'},
            // {key: '1', value: 'DEXX Drive'},
            // {key: '4', value: 'DEXX Lille'},
            {key: '5', value: 'DEXX Annemasse'},
            {key: '6', value: 'DEXX Mulhouse'},
            {key: '7', value: 'DEXX Valence'},
            {key: '8', value: 'DEXX Lyon'},
        ]
    
        return (
            <FusePageSimple
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                      <div className="flex flex-1 items-center justify-between p-24">
                         <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                  <Icon className="text-18" color="action">home</Icon>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">API's</Typography>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">Stock Export</Typography>
                            </div>
                            <Typography variant="h6">Stock Export</Typography>
                         </div>
                      </div>
                }
                content={
                        <div>
                            <FuseAnimateGroup
                                className="flex flex-wrap"
                                enter={{
                                    animation: "transition.slideUpBigIn"
                                }}
                            >

                            <div className="flex flex-wrap w-full p-12">
                                <div className="flex w-full p-12 md:w-1/3">
                                    <Card className="w-full">
                                        <CardHeader 
                                        className={classes.cardHeader}
                                        title={
                                            <>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Choisissez la période
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                            <b>Du</b> {this.state.fromdate} <b>au</b> {this.state.todate} 
                                            {generatedStockExport.length > 0 ? " Rows found : " + generatedStockExport.length : null}
                                            </Typography>
                                            </>
                                        } />
                                        <CardContent>

                                <FormControl className="w-full">
                                    <InputLabel id="land-label">{t("COMPANY")}</InputLabel>
                                    <Select
                                        id="land-select"
                                        value={this.state.filterCompany}
                                        defaultValue="8"
                                        onChange={(event) => this.setFilterCompany(event.target.value)}
                                    >
                                        {companylist.map(row => (
                                            <MenuItem key={row.key} value={row.key}>{row.value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <br />
                                <br />
                            {/* <div className="flex flex-wrap w-full justify-center p-6" elevation={1}>
                                <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('thisweek')}>
                                    {t("THISWK")}
                                </Button>
                                <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('lastweek')}>
                                    {t("LASTWK")}
                                </Button>
                            </div>
                                            <TextField
                                                id="fromdate"
                                                label= {t("STARTDATE") + " " + t("DATE")}
                                                type="date"
                                                value={this.state.fromdate}
                                                // defaultValue={this.state.fromdate}
                                                onChange={this.handleFromDate}
                                                className={classes.datePicker}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }} 
                                            />
                                            <br />
                                            <br />
                                            <TextField
                                                id="todate"
                                                label= {t("ENDDATE") + " " + t("DATE")}
                                                type="date"
                                                value={this.state.todate}
                                                // defaultValue={this.state.todate}
                                                onChange={this.handleToDate}
                                                className={classes.datePicker}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }} 
                                            />
                                            <br />
                                            <br /> */}
                                            <div className="w-full flex flex-wrap">
                                                <div className="w-full xs:w-full sm:w-1/2 md:w-full lg:w-1/2 p-6">
                                                <Button 
                                                    variant="contained" 
                                                    color="primary" 
                                                    fullWidth 
                                                    startIcon={<Icon>autorenew</Icon>}
                                                    onClick={this.handleGenerateClick}>
                                                    {/* <Icon className={"m-10"}>autorenew</Icon>  */}
                                                    {t("PREVIEW")}
                                                </Button>
                                                </div>
                                                <div className="w-full xs:w-full sm:w-1/2 md:w-full lg:w-1/2 p-6">                                            
                                                <Tooltip title="Envoyer par Email a comptabilité">
                                                    <Badge badgeContent={generatedStockExport.length} color="primary" className="w-full">
                                                        <Button 
                                                            variant="contained"
                                                            color="secondary"
                                                            // className="w-full"
                                                            fullWidth
                                                            startIcon={<Icon>save_alt</Icon>}
                                                            onClick={this.downloadExcel}>
                                                            {t("SEND")}
                                                        </Button>
                                                    </Badge>
                                                </Tooltip>
                                                </div>
                                            </div>
                                            
                                    {this.props.loadingGeneratedStockExport ? <>
                                    <Box display="flex" alignItems="center">
                                        <Box width="100%" mr={1}>
                                            <LinearProgress color="primary" className="mb-12" />
                                        </Box>
                                        {/* <Box minWidth={35}>
                                            <Typography variant="body2" color="textSecondary">{`${Math.round(0,)}%`}</Typography>
                                        </Box> */}
                                    </Box>
                                    </> : this.props.StockExporterror}
                                        </CardContent>
                                    </Card>
                                </div>
                                {/* {generatedStockExport.length > 0 ?  */}
                                <div className="flex w-full p-12 md:w-2/3">
                                    <Card className="w-full">
                                            <ReactTable
                                                style={{border: '0px'}}
                                                className="-striped -highlight border-0"
                                                data={generatedStockExport}
                                                columns={[
                                                    {
                                                        Header    : "Stock",
                                                        accessor  : "voortxt",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-end",
                                                        // width: 100,
                                                    },
                                                    {
                                                        Header    : "Total",
                                                        accessor  : "total",
                                                        filterable: true,
                                                        className: "justify-end",
                                                        // headerStyle: {textAlign: 'right'},
                                                        // width: 100,
                                                        // Cell: row => row.original.total
                                                        Cell: row => <div>
                                                            <Tooltip title={t("COPY")} placement="left">
                                                            <button className="text-right" onClick={() => copyCodeToClipboard(row.original.total)}>
                                                                € {row.original.total.toLocaleString('nl-NL', { minimumFractionDigits:2, maximumFractionDigits:2 })}&nbsp;
                                                                <Icon className="text-18">file_copy</Icon></button>
                                                            </Tooltip>
                                                            </div>
                                                    },
                                                ]}
                                                pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                                pageSize={10}
                                                noDataText="No customers found"
                                            />
                                    </Card>
                                </div>
                                {/* : null } */}
                            </div>
                                </FuseAnimateGroup>
                            </div>
                }
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        loadingGeneratedStockExport : state.api.loadingGeneratedStockExport,
        generatedStockExport: state.api.generateStockExport,
        StockExporterror: state.api.error,
        // loading: state.api.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGenerateExport: (filterCompany) => dispatch(actions.generateStockExport(filterCompany)),
        onDownloadExcel: (data, filterCompany) => dispatch(actions.downloadExcelStockExport(data, filterCompany))
        // onFetchApis: () => dispatch(actions.fetchApis()),
        // onSendDailyOmk: () => dispatch(actions.sendDailyOmk())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles, {withTheme: true})(withTranslation()(DexxStockExport)));