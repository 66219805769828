import React from 'react';
import { useQuery } from 'react-query'
import axios from 'axios';
import ReactTable from "react-table";
import { FuseAnimate, FusePageSimple } from '../../../../@fuse';
import { Icon, Typography, Paper, IconButton, Tooltip, Badge, LinearProgress, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import DonutChart, { donutData } from '../../../components/Widgets/DonutChart';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const CCDashboard = () => {
    const { t } = useTranslation();
    const { data: donutturnover, isLoading } = useQuery('getdonut',
        async () => {
            const res = await axios.get(
                "cashCarry/GetCCwInternetChart"
            );
            return res.data;
        },  
        {
            staleTime: 60000
        }
    );
    const { data: tableturnover } = useQuery('gettable',
        async () => {
            const res = await axios.get(
                "cashCarry/GetCCwInternetTable"
            );
            return res.data;
        },  
        {
            staleTime: 60000
        }
    );
    
    let CCInternetDonut = null;
    CCInternetDonut = _.cloneDeep(donutData);
    if (donutturnover?.length > 0) {
        CCInternetDonut = _.cloneDeep(donutData);
        const labels = [];
        const dataset = [];
        donutturnover.map(t => labels.push(t.type) && dataset.push(t.turnover.toFixed(0)));

        CCInternetDonut['mainChart'].datasets['TW'][0].data = dataset;
        CCInternetDonut['mainChart'].labels = labels;
        CCInternetDonut.title = 'Turnover Distribution';
    }

    // console.log(tableturnover);
    // console.log(CCInternetDonut);
    return (
        <FusePageSimple
            header={
                <div className="flex flex-1 items-center justify-between p-24">
                    <div className="flex flex-col">
                        <div className="flex items-center mb-16">
                            <Icon className="text-18" color="action">home</Icon>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">C&C</Typography>
                        </div>
                        <Typography variant="h6">Dashboard</Typography>
                        {/* <Typography className="text-12">van klanten uit jouw debiteurgroep en van showroombezoeken waarbij je betrokken bent</Typography> */}
                    </div>
                </div>
            }
            content={!isLoading ?
                <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div className="p-12">
                    <div className="flex flex-wrap">                    
                        <div className={`widget flex w-full sm:w-full md:w-1/3 p-12`}>
                            <Paper className="w-full rounded-8 shadow-none border-1">
                                <React.Fragment>
                                    <div className="flex items-center justify-between px-16 h-64 border-b-1">
                                        <Typography className="text-16">{t("TURNOVERPERCATEGORIES")}</Typography>
                                    </div>
                                    <div className="h-400 w-full p-24">
                                        {!isLoading && CCInternetDonut ?
                                            <DonutChart data={CCInternetDonut} />
                                            :
                                            <>
                                                <Skeleton className="rounded m-auto" variant="rect" width={'60%'} height={250} />
                                                <Skeleton className="mt-12 m-auto" variant="text" width={240} />
                                            </>
                                        }
                                    </div>
                                </React.Fragment>
                            </Paper>
                        </div>
                        <div className={`widget flex w-full sm:w-full md:w-2/3 p-12`}>
                        <ReactTable
                            className="-striped -highlight border-0"
                            data={tableturnover}
                            columns={[
                                {
                                    Header: t("TYPE"),
                                    accessor: "type",
                                    filterable: true,
                                    className: "font-bold justify-end",
                                    width: 250,
                                },
                                {
                                    Header: t("CUSTOMER"),
                                    accessor: "clients",
                                    filterable: true,
                                    width: 100,
                                    className: "justify-end"
                                },
                                {
                                    Header: t("TURNOVER"),
                                    accessor: "turnover",
                                    filterable: true,
                                    width: 150,
                                    className: "font-bold justify-end",
                                    Cell: row => <div>€ {row.value.toLocaleString('nl-NL', { minimumFractionDigits:2, maximumFractionDigits:2 })}</div>
                                },
                            ]}
                            pageSizeOptions={[5, 10, 20]}
                            defaultPageSize={10}
                            noDataText="Geen bezoeken gevonden"
                        />
                        </div>

                    <div className="w-full p-12">
                    </div>
                    </div>
                </div>
                </FuseAnimate>
                : <div className="flex h-full">
                    <div className="m-auto">
                        <LinearProgress />
                        <Typography>Loading...</Typography>
                    </div>
                </div>
            }
        />
    );
};

export default CCDashboard;