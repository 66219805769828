import React, { Component } from 'react';
import {withStyles} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import {FusePageSimple, FuseAnimateGroup} from '../../../../@fuse';
import {Icon, Typography, Tooltip, TextField, Card, CardContent, CardHeader, Button, LinearProgress, Box, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import ReactTable from "react-table";
import {connect} from 'react-redux';
import * as actions from '../../../store/api/actions';
import moment from 'moment';

const styles = theme => ({
    layoutRoot: {},
    datePicker: {
        // minWidth: '155px',
        width: '100%',
    },
    floatRight: {
        float: 'right',
        marginLeft: '4px',
    },
    cardHeader: {
        backgroundColor: '#f6f8f8',
        borderBottom: '1px solid',
        borderColor: '#dee5e7 !important;'
    },
    successButton: {
        color: '#27c24c !important'
    },
});

class DexxCbsExport extends Component {

    state = {
        fromdate: new Date().toISOString().slice(0, 10),
        todate: new Date().toISOString().slice(0, 10),
        startLoading: false,
        finishedLoading: false,
        percentage: 0,
        filterCompany: '570',
        debnrValue: '',
        debnrError: false,
        // fromdate: new Date(),
        // fromdate: moment(this.getMonday(fromdate)).format('YYYY-MM-DD'),
        // todate: moment(new Date()).format('YYYY-MM-DD'),
    }

    componentDidMount() {
        this.handleDateButtonClick('yesterday');
        // if(this.props.generatedDexxCBSExport.length === 0) {
        //     this.props.onGenerateExport(this.state.fromdate, this.state.todate);
        // }
    }
    
    handleFromDate = event => {
        this.setState({fromdate: event.target.value})
    }
    
    handleToDate = event => {
        this.setState({todate: event.target.value})
    }

    handleGenerateClick = () => {
        this.setState({finishedLoading: false, startLoading: true, percentage: 0});
        this.props.onGenerateExport(this.state.fromdate, this.state.todate, this.state.filterCompany);
    }

    finishedLoading = () => {
        this.setState({finishedLoading: true, startLoading: false, percentage: 100});
    }

    downloadExcel = () => {
        this.props.onDownloadExcel(this.state.fromdate, this.state.todate, this.state.filterCompany);
    }
    downloadDebnrExcel = () => {
        if(this.state.debnrValue != ''){
        this.setState({debnrError: false})
        this.props.onDownloadDebnrExcel(this.state.fromdate, this.state.todate, this.state.debnrValue);
        }else{
        this.setState({debnrError: true})
        }
    }
    
    applyDates = () => {
        // const momentFormatted = moment(this.state.startDate).format('YYYY-MM-DD');
        // console.log(momentFormatted);
        const startDate = moment(this.state.startDate).format('YYYY-MM-DD');
        const endDate = moment(this.state.endDate).format('YYYY-MM-DD');
        this.setState({fromdate: startDate, todate: endDate})
    }

    handleDateButtonClick = buttonClicked => {
        let startDate = new Date();
        let endDate = new Date();
        switch(buttonClicked) {
            case 'yesterday':
                startDate.setDate(startDate.getDate() - 1);
                endDate.setDate(endDate.getDate() - 1);
                break;
            case 'thisweek': 
                startDate = this.getMonday(startDate);
                break;
            case 'thismonth': 
                startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                break;
            case 'thisyear': 
                startDate = new Date(startDate.getFullYear(), 0, 1)
                break;
            case 'lastweek': 
                startDate.setDate(startDate.getDate()-7);
                startDate = this.getMonday(startDate);
                endDate = moment(startDate).add('days', 4).toDate();
                break;
            case 'lastmonth': 
                startDate = new Date(startDate.getFullYear(), startDate.getMonth()-1, 1);
                // startDate.setHours(-1);
                // startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                // startDate.setHours(-24);
                endDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
                endDate.setHours(-4);
                break;
            case 'lastyear': 
                startDate = new Date(startDate.getFullYear() - 1, 0, 1);
                endDate = new Date(endDate.getFullYear() - 1, 11, 31);
                break;
            case 'defaultdate': 
                startDate.setDate(startDate.getDate() - 1);
                endDate.setDate(endDate.getDate() - 1);
                break;
            default: break;
        }
        this.setState({startDate, endDate}, this.applyDates);        
    }

    getMonday = date => {
        let day = date.getDay() || 7;
        if( day !== 1 ) 
            date.setHours(-24 * (day - 1)); 
        return date;
    }

    setFilterCompany = (value) => {
        this.setState({filterCompany: value});
    }
    setDebnr = (value) => {
        console.log(value)
        this.setState({debnrValue: value});
    }
    render() {
        const {classes, t, generatedDexxCBSExport} = this.props;
        // console.log(" company : " + this.state.filterCompany);
        // console.log(" finished : " + this.state.finishedLoading);
        
        const companylist = [
            // {key: 'all', value: 'Tous'},
            {key: '570', value: 'DEXX Lyon'},
            {key: '222', value: 'DEXX Valence'},
            {key: '595', value: 'DEXX Mulhouse'},
            {key: '565', value: 'DEXX Annemasse'},
            {key: 'DDJ00', value: 'DEXX Drive'}
        ]
    
        return (
            <FusePageSimple
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                      <div className="flex flex-1 items-center justify-between p-24">
                         <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                  <Icon className="text-18" color="action">home</Icon>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">API's</Typography>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">DEXX DEB Export</Typography>
                            </div>
                            <Typography variant="h6">DEXX DEB Export</Typography>
                         </div>
                      </div>
                }
                content={
                        <div>
                            <FuseAnimateGroup
                                className="flex flex-wrap"
                                enter={{
                                    animation: "transition.slideUpBigIn"
                                }}
                            >

                            <div className="flex flex-wrap w-full p-12 md:w-1/2">
                                <div className="flex w-full p-12">
                                    <Card className="w-full">
                                        <CardHeader 
                                        className={classes.cardHeader}
                                        title={
                                            <>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Importation DEB
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                            <b>Du</b> {this.state.fromdate} <b>au</b> {this.state.todate} 
                                            {/* {generatedDexxCBSExport.length > 0 ? " lignes trouvés : " + generatedDexxCBSExport.length : null} */}
                                            </Typography>
                                            </>
                                        } />
                                        <CardContent>
                                            
                                
                                <FormControl className="w-full">
                                    <InputLabel id="land-label">{t("COMPANY")}</InputLabel>
                                    <Select
                                        id="land-select"
                                        value={this.state.filterCompany}
                                        onChange={(event) => this.setFilterCompany(event.target.value)}
                                    >
                                        {companylist.map(row => (
                                            <MenuItem key={row.key} value={row.key}>{row.value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            <div className="flex flex-wrap w-full justify-center p-6" elevation={1}>
                                <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleDateButtonClick('thismonth')}>
                                    {t("THISMT")}
                                </Button>
                                <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleDateButtonClick('lastmonth')}>
                                    {t("LASTMT")}
                                {/* <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('yesterday')}>
                                    {t("YESTERDAY")}
                                </Button>
                                <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('thisweek')}>
                                    {t("THISWK")}
                                </Button> */}
                                {/* <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('thisyear')}>
                                    {t("THISYR")}
                                </Button> */}
                                {/* <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('lastweek')}>
                                    {t("LASTWK")}
                                </Button> */}
                                </Button>
                                {/* <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('lastyear')}>
                                    {t("LASTYR")}
                                </Button> */}
                                {/* <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('defaultdate')}>
                                    {t("DEFAULTDT")}
                                </Button> */}
                            </div>
                                            <TextField
                                                id="fromdate"
                                                label= {t("STARTDATE") + " " + t("DATE")}
                                                type="date"
                                                value={this.state.fromdate}
                                                // defaultValue={this.state.fromdate}
                                                onChange={this.handleFromDate}
                                                className={classes.datePicker}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }} 
                                            />
                                            <br />
                                            <br />
                                            <TextField
                                                id="todate"
                                                label= {t("ENDDATE") + " " + t("DATE")}
                                                type="date"
                                                value={this.state.todate}
                                                // defaultValue={this.state.todate}
                                                onChange={this.handleToDate}
                                                className={classes.datePicker}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }} 
                                            />
                                            <br />
                                            <br />
                                            <div className="w-full flex flex-wrap">
                                                {/* <div className="w-full xs:w-full sm:w-1/2 md:w-full lg:w-1/2 p-6">
                                                <Button 
                                                    variant="contained" 
                                                    color="primary" 
                                                    fullWidth 
                                                    startIcon={<Icon>autorenew</Icon>}
                                                    onClick={this.handleGenerateClick}>
                                                    <Icon className={"m-10"}>autorenew</Icon> 
                                                    {t("PREVIEW")}
                                                </Button>
                                                </div> */}
                                                <div className="w-full p-6">                                            
                                                <Tooltip title="Download Excel">
                                                    {/* <Badge badgeContent={generatedDexxCBSExport.length} color="primary" className="w-full"> */}
                                                        <Button 
                                                            variant="contained"
                                                            color="secondary"
                                                            // className="w-full"
                                                            fullWidth
                                                            startIcon={<Icon>save_alt</Icon>}
                                                            onClick={this.downloadExcel}>
                                                            {t("DOWNLOAD")}
                                                        </Button>
                                                    {/* </Badge> */}
                                                </Tooltip>
                                                </div>
                                            </div>
                                            
                                    {this.props.loadingGeneratedDexxCBSExport ? <>
                                    <Box display="flex" alignItems="center">
                                        <Box width="100%" mr={1}>
                                            <LinearProgress color="primary" className="mb-12" />
                                        </Box>
                                        {/* <Box minWidth={35}>
                                            <Typography variant="body2" color="textSecondary">{`${Math.round(0,)}%`}</Typography>
                                        </Box> */}
                                    </Box>
                                    </> : this.props.DexxCBSExporterror}
                                        </CardContent>
                                    </Card>
                                </div>
                                {generatedDexxCBSExport.length > 0 ? 
                                <div className="flex w-full p-12 md:w-1/2">
                                    <Card className="w-full">
                                            <ReactTable
                                                style={{border: '0px'}}
                                                className="-striped -highlight border-0"
                                                data={generatedDexxCBSExport}
                                                columns={[
                                                    {
                                                        Header    : "Debnr",
                                                        accessor  : "debnr",
                                                        filterable: true,
                                                        className: "justify-center font-bold",
                                                        width: 75,
                                                    },
                                                    // {
                                                    //     Header    : t("NAME"),
                                                    //     accessor  : "debnaam",
                                                    //     filterable: true,
                                                    //     width: 200,
                                                    // },
                                                    {
                                                        Header    : t("DATE"),
                                                        accessor  : "datum",
                                                        filterable: true,
                                                        className: "justify-center font-bold",
                                                        width: 100,
                                                        Cell: row => moment(row.original.datum).format('DD/MM/YYYY')
                                                        // <Tooltip title={t("VALIDATE") + " : " + moment(row.original).format('DD-MM-YYYY @ HH:mm')}>
                                                        //     <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                                        // </Tooltip>
                                                    },
                                                    {
                                                        Header    : "Factnr",
                                                        accessor  : "factnr",
                                                        filterable: true,
                                                        className: "justify-center",
                                                        width: 100,
                                                    },
                                                    {
                                                        Header    : "StatNr",
                                                        accessor  : "statnr",
                                                        filterable: true,
                                                        className: "justify-center",
                                                        width: 125,
                                                    },
                                                    {
                                                        Header    : "Or",
                                                        accessor  : "payS_ORIGINE",
                                                        filterable: true,
                                                        // className: "justify-center",
                                                        width: 50,
                                                    },
                                                    {
                                                        Header    : "Pr",
                                                        accessor  : "payS_PROVENANCE",
                                                        filterable: true,
                                                        // className: "justify-center",
                                                        width: 50,
                                                    },
                                                    {
                                                        Header    : "Qte",
                                                        accessor  : "totstuks",
                                                        filterable: true,
                                                        className: "justify-center",
                                                        width: 100,
                                                    },
                                                    {
                                                        Header    : "Dep",
                                                        accessor  : "departement",
                                                        filterable: true,
                                                        className: "justify-center",
                                                        width: 100,
                                                    },
                                                    {
                                                        Header    : t("total"),
                                                        accessor  : "waarde",
                                                        filterable: false,
                                                        className: "float-rigth",
                                                        // headerStyle: {textAlign: 'right'},
                                                        width: 100,
                                                        Cell: row => <div style={{ textAlign: "right" }}>{"€ " + row.original.waarde.toFixed(2)}</div>
                                                    },
                                                ]}
                                                pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                                pageSize={10}
                                                noDataText="No customers found"
                                            />
                                    </Card>
                                </div>
                                : null }
                            </div>
                            {/* New Card Add Start */}
                            <div className="flex flex-wrap w-full p-12 md:w-1/2">
                                <div className="flex w-full p-12">
                                    <Card className="w-full">
                                        <CardHeader 
                                        className={classes.cardHeader}
                                        title={
                                            <>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Expedetion DEB
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                            <b>Du</b> {this.state.fromdate} <b>au</b> {this.state.todate} 
                                            {/* {generatedDexxCBSExport.length > 0 ? " lignes trouvés : " + generatedDexxCBSExport.length : null} */}
                                            </Typography>
                                            </>
                                        } />
                                        <CardContent>
                                            
                                
                                <FormControl className="w-full">
                                    {/* <input max={6} onChange={(event) => this.setDebnr(event.target.value)} id="debnr" label="Debnr" variant="outlined" ></input> */}
                                <TextField inputProps={{maxLength:6}} onChange={(event) => this.setDebnr(event.target.value)} id="debnr" label="Debnr" variant="outlined" />
                                {this.state.debnrError ? (
                                    <span className='error'>Enter debnr</span>
                                ) : ('')}
                                
                                    {/* <InputLabel id="land-label">{t("COMPANY")}</InputLabel>
                                    <Select
                                        id="land-select"
                                        value={this.state.filterCompany}
                                        onChange={(event) => this.setFilterCompany(event.target.value)}
                                    >
                                        {companylist.map(row => (
                                            <MenuItem key={row.key} value={row.key}>{row.value}</MenuItem>
                                        ))}
                                    </Select> */}
                                </FormControl>
                            <div className="flex flex-wrap w-full justify-center p-6" elevation={1}>
                                <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleDateButtonClick('thismonth')}>
                                    {t("THISMT")}
                                </Button>
                                <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleDateButtonClick('lastmonth')}>
                                    {t("LASTMT")}
                                {/* <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('yesterday')}>
                                    {t("YESTERDAY")}
                                </Button>
                                <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('thisweek')}>
                                    {t("THISWK")}
                                </Button> */}
                                {/* <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('thisyear')}>
                                    {t("THISYR")}
                                </Button> */}
                                {/* <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('lastweek')}>
                                    {t("LASTWK")}
                                </Button> */}
                                </Button>
                                {/* <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('lastyear')}>
                                    {t("LASTYR")}
                                </Button> */}
                                {/* <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('defaultdate')}>
                                    {t("DEFAULTDT")}
                                </Button> */}
                            </div>
                                            <TextField
                                                id="fromdate"
                                                label= {t("STARTDATE") + " " + t("DATE")}
                                                type="date"
                                                value={this.state.fromdate}
                                                // defaultValue={this.state.fromdate}
                                                onChange={this.handleFromDate}
                                                className={classes.datePicker}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }} 
                                            />
                                            <br />
                                            <br />
                                            <TextField
                                                id="todate"
                                                label= {t("ENDDATE") + " " + t("DATE")}
                                                type="date"
                                                value={this.state.todate}
                                                // defaultValue={this.state.todate}
                                                onChange={this.handleToDate}
                                                className={classes.datePicker}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }} 
                                            />
                                            <br />
                                            <br />
                                            <div className="w-full flex flex-wrap">
                                                {/* <div className="w-full xs:w-full sm:w-1/2 md:w-full lg:w-1/2 p-6">
                                                <Button 
                                                    variant="contained" 
                                                    color="primary" 
                                                    fullWidth 
                                                    startIcon={<Icon>autorenew</Icon>}
                                                    onClick={this.handleGenerateClick}>
                                                    <Icon className={"m-10"}>autorenew</Icon> 
                                                    {t("PREVIEW")}
                                                </Button>
                                                </div> */}
                                                <div className="w-full p-6">                                            
                                                <Tooltip title="Download Excel">
                                                    {/* <Badge badgeContent={generatedDexxCBSExport.length} color="primary" className="w-full"> */}
                                                        <Button 
                                                            variant="contained"
                                                            color="secondary"
                                                            // className="w-full"
                                                            fullWidth
                                                            startIcon={<Icon>save_alt</Icon>}
                                                            onClick={this.downloadDebnrExcel}>
                                                            {t("DOWNLOAD")}
                                                        </Button>
                                                    {/* </Badge> */}
                                                </Tooltip>
                                                </div>
                                            </div>
                                            
                                    
                                    {this.props.loadingGeneratedDexxCBSExportDebnr ? <>
                                    <Box display="flex" alignItems="center">
                                        <Box width="100%" mr={1}>
                                            <LinearProgress color="primary" className="mb-12" />
                                        </Box>
                                        {/* <Box minWidth={35}>
                                            <Typography variant="body2" color="textSecondary">{`${Math.round(0,)}%`}</Typography>
                                        </Box> */}
                                    </Box>
                                    </> : this.props.DebnrDexxCBSExporterror}
                                        </CardContent>
                                    </Card>
                                </div>
                                {generatedDexxCBSExport.length > 0 ? 
                                <div className="flex w-full p-12 md:w-1/2">
                                    <Card className="w-full">
                                            <ReactTable
                                                style={{border: '0px'}}
                                                className="-striped -highlight border-0"
                                                data={generatedDexxCBSExport}
                                                columns={[
                                                    {
                                                        Header    : "Debnr",
                                                        accessor  : "debnr",
                                                        filterable: true,
                                                        className: "justify-center font-bold",
                                                        width: 75,
                                                    },
                                                    // {
                                                    //     Header    : t("NAME"),
                                                    //     accessor  : "debnaam",
                                                    //     filterable: true,
                                                    //     width: 200,
                                                    // },
                                                    {
                                                        Header    : t("DATE"),
                                                        accessor  : "datum",
                                                        filterable: true,
                                                        className: "justify-center font-bold",
                                                        width: 100,
                                                        Cell: row => moment(row.original.datum).format('DD/MM/YYYY')
                                                        // <Tooltip title={t("VALIDATE") + " : " + moment(row.original).format('DD-MM-YYYY @ HH:mm')}>
                                                        //     <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                                        // </Tooltip>
                                                    },
                                                    {
                                                        Header    : "Factnr",
                                                        accessor  : "factnr",
                                                        filterable: true,
                                                        className: "justify-center",
                                                        width: 100,
                                                    },
                                                    {
                                                        Header    : "StatNr",
                                                        accessor  : "statnr",
                                                        filterable: true,
                                                        className: "justify-center",
                                                        width: 125,
                                                    },
                                                    {
                                                        Header    : "Or",
                                                        accessor  : "payS_ORIGINE",
                                                        filterable: true,
                                                        // className: "justify-center",
                                                        width: 50,
                                                    },
                                                    {
                                                        Header    : "Pr",
                                                        accessor  : "payS_PROVENANCE",
                                                        filterable: true,
                                                        // className: "justify-center",
                                                        width: 50,
                                                    },
                                                    {
                                                        Header    : "Qte",
                                                        accessor  : "totstuks",
                                                        filterable: true,
                                                        className: "justify-center",
                                                        width: 100,
                                                    },
                                                    {
                                                        Header    : "Dep",
                                                        accessor  : "departement",
                                                        filterable: true,
                                                        className: "justify-center",
                                                        width: 100,
                                                    },
                                                    {
                                                        Header    : t("total"),
                                                        accessor  : "waarde",
                                                        filterable: false,
                                                        className: "float-rigth",
                                                        // headerStyle: {textAlign: 'right'},
                                                        width: 100,
                                                        Cell: row => <div style={{ textAlign: "right" }}>{"€ " + row.original.waarde.toFixed(2)}</div>
                                                    },
                                                ]}
                                                pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                                pageSize={10}
                                                noDataText="No customers found"
                                            />
                                    </Card>
                                </div>
                                : null }
                            </div>
                            {/* New Card Add End */}

                                </FuseAnimateGroup>
                            </div>
                }
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        loadingGeneratedDexxCBSExport : state.api.loadingGeneratedDexxCBSExport,
        generatedDexxCBSExport: state.api.generateDexxCBSExport,
        DexxCBSExporterror: state.api.error,
        loadingGeneratedDexxCBSExportDebnr : state.api.loadingGeneratedDexxCBSExportDebnr,
        DebnrDexxCBSExporterror: state.api.errorDebnr,
        // loading: state.api.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGenerateExport: (fromdate, todate, filterCompany) => dispatch(actions.generateDexxCBSExport(fromdate, todate, filterCompany)),
        onDownloadExcel: (fromdate, todate, filterCompany) => dispatch(actions.downloadExcelDexxCBSExport(fromdate, todate, filterCompany)),
        onDownloadDebnrExcel: (fromdate, todate, debnrValue) => dispatch(actions.DownloadExcelDexxCBSExportExpedition(fromdate, todate, debnrValue))
        // onFetchApis: () => dispatch(actions.fetchApis()),
        // onSendDailyOmk: () => dispatch(actions.sendDailyOmk())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles, {withTheme: true})(withTranslation()(DexxCbsExport)));